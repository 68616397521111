@import '@client/assets/scss/argon-dashboard/custom/_colorVariables.scss';

.Cover {
  background: linear-gradient(
    180deg,
    $primary 0%,
    $primary 250px,
    $base 100px,
    $base 100%
  );
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 1rem;
  padding-top: 1rem;

  .Container {
    height: 100%;
  }

  .Header {
    color: white;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    label {
      margin-top: 16px;
    }
  }
}

.FloatTrappy {
  bottom: 1.5rem;
  position: sticky;
}
