@import '@client/assets/scss/argon-dashboard/custom/_colorVariables.scss';

.TextRenderer {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;

  li::before {
    color: $gray-700;
    content: '\f111';
    display: inline-block;
    font-family: 'Font Awesome 5 Free', sans-serif;
    font-size: 0.5rem;
    font-weight: 900;
    margin-right: 0.5rem;
    vertical-align: middle;
  }

  ul {
    list-style: none;
    margin: 0 0 0.2rem;
    padding-left: 0;
  }
}

.CiteBlock {
  border-left: $gray-600 4px solid;
  color: $gray-600;
  padding-left: 0.5rem;
}
