@import '@client/assets/scss/argon-dashboard/custom/_colorVariables.scss';

.Prototype {
  min-height: 100vh;
  position: relative;

  .Navbar {
    background-color: $primary;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 600;

    .Nav {
      align-items: center;
      color: $white;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 1rem;
    }
  }
}
