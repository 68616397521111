@import '@client/assets/scss/argon-dashboard/custom/_colorVariables.scss';

.TrappySays {
  align-items: center;
  display: flex;
  flex-direction: row;

  .Balloon {
    box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
    width: 100%;
  }

  &.directionTop {
    flex-direction: column;
  }
}
