.overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  bottom: 0;
  cursor: pointer; /* Add a pointer on hover */
  display: flex;
  flex-direction: column;
  height: 100%; /* Full height (cover the whole page) */
  justify-content: center;
  left: 0;
  position: fixed; /* Sit on top of the page content */
  right: 0;
  top: 0;
  width: 100%; /* Full width (cover the whole page) */
  z-index: 1000; /* Specify a stack order in case you're using a different order for other elements */
}
