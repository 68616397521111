.Trappy {
  width: 100px;
  height: 100px;

  &.large {
    width: 200px;
    height: 200px;
  }
}

.Balloon {
  position: relative;
  display: inline-block;
  margin: 1.5em 0;
  padding: 7px 10px;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  font-size: 14px;
  background: #e0edff;
  border-radius: 10px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -25px;
    margin-top: -15px;
    border: 15px solid transparent;
    border-right: 15px solid #e0edff;
  }
}
